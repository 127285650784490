import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetBlogs, GetLastBlogs} from "../../redux/actions";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import "../../assets/styles/homepage/homepageNews.scss"
import NewsCard from "../uiElements/cards/newsCard/NewsCard";
import {Link, useLocation} from "react-router-dom";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";


function HomepageNews(props){
    const {staticTexts,lastNews} = props;
    const [news,setNews] = useState()
    const location = useLocation();

    useEffect(() => {
        props.GetLastBlogs({limit:3})
        setNews(lastNews?.itemsList)
    },[location.pathname])

    return <div className={'homepage-news-wrapper'}>
        <div className={"homepage-news-block"}>
            <div className={'header-news-block'}>
                <div className={'news-text'}>
                    <div className={'homepage-news-title'}>
                        {staticTexts?.homepage_works_title}
                    </div>
                    <div className={'homepage-news-description'}>
                        {staticTexts?.homepage_works_description}
                    </div>
                </div>
                <Link to={getUrlWithLocal('/works')} className={'news-btn'}>
                    {staticTexts?.homepage_works_button}
                </Link>
            </div>
            <div className={'news-wrapper'}>
                <div className={'news'}>
                    {
                        news?.length ? news.map(item =>{
                            return <NewsCard key={item}
                                             item={item}/>
                        }) : ''
                    }
                </div>
                <Link to={getUrlWithLocal('/works')} className={'news-btn'}>
                    {staticTexts?.homepage_works_button}
                </Link>
            </div>
        </div>
    </div>

}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'lastNews',
    ])
};
const mapDispatchToProps = {
    GetBlogs,
    GetLastBlogs
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageNews);