// import packages
import React from 'react';

//Import Components

//Import assets
import "../assets/styles/components/newsModal.scss"

// Import utils
import {connect} from "react-redux";
import {getPropsFromState} from "../redux/mapStateToProps";
import {Modal} from "antd";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import moment from "moment";
import {ModalCloseIcon} from "../assets/images";


function NewsModal(props){
    const {modalOpen,toggleModalOpen,news} = props

    function closeModal(){
        toggleModalOpen()
    }

    return <Modal centered
                  title={''}
                  visible={modalOpen}
                  okText={'Ուղարկել միացնման հայտը'}
                  cancelText={'Չեղարկել'}
                  closeIcon={<ModalCloseIcon/>}
                  className={'newsModal'}
                  onOk={'Ուղարկել միացնման հայտը'}
                  onCancel={closeModal}>
        <div className={'news-modal-wrapper'}>
            <div className={'news-block-img'}>
                <img src={generateImageMediaUrl(news?.mediaMain?.path)}/>
            </div>
            <div className={'news-block-info'}>
                <div className={'news-date'}>
                    {moment(news?.createdAt).format("DD.MM.YYYY")}
                </div>
                <div className={'news-block-title'}>
                    {news?.title}
                </div>
                <div className={'news-block-description'}>
                    {news?.description}
                </div>
            </div>
        </div>
           </Modal>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsModal)